


























import Vue from "vue";
import LineChart from "@/components/LineChart.vue";
import Component from "vue-class-component";
import { movingAvg, round } from "@/utils/stats";

interface ProvinceDayStats {
  data: string;
  stato: string;
  codice_regione: number;
  denominazione_regione: string;
  codice_provincia: number;
  denominazione_provincia: string;
  sigla_provincia: string;
  lat: number;
  long: number;
  totale_casi: number;
}

interface Province {
  code: string;
  name: string;
}

@Component({
  components: {
    LineChart,
  },
})
export default class Provinces extends Vue {
  private loaded = false;
  private chartdata = {};
  private provinces: Province[] = [];
  private province = "";
  private proCivData: ProvinceDayStats[] = [];
  private movingAverage = 2;

  async mounted(): Promise<void> {
    this.loaded = false;

    if (this.$store.state.provincesData.length) {
      this.init(this.$store.state.provincesData);
      return;
    }

    fetch(
      "https://raw.githubusercontent.com/pcm-dpc/COVID-19/master/dati-json/dpc-covid19-ita-province.json"
    )
      .then((res) => res.json())
      .then((proCivData: ProvinceDayStats[]) => {
        this.$store.state.provincesData = proCivData;

        this.init(proCivData);
      });
  }

  private init(proCivData: ProvinceDayStats[]): void {
    this.proCivData = proCivData;

    const inserted: string[] = [];

    this.provinces = proCivData
      .reduce((carrier: Province[], stats) => {
        let provinceCode = stats.sigla_provincia;
        if (stats.denominazione_provincia === "Napoli") {
          provinceCode = "NA";
        }
        carrier.push({
          code: provinceCode,
          name: stats.denominazione_provincia,
        });

        return carrier;
      }, [])
      .filter((province) => {
        if (inserted.indexOf(province.code) > -1) {
          return false;
        }

        inserted.push(province.code);

        return province.code;
      })
      .sort((prov1, prov2) => {
        if (prov1.code === "RE") {
          return -10;
        }
        if (prov2.code === "RE") {
          return 10;
        }
        return prov1.name.localeCompare(prov2.name);
      });

    this.province = this.provinces[0].code;

    this.plot(this.province);
  }

  private plot(provinceCode: string) {
    const labels: string[] = [];
    let cases: number[] = [];
    let news: number[] = [];

    const dataToPlot = this.proCivData.filter(
      (stats) =>
        stats.sigla_provincia === provinceCode ||
        (provinceCode === "NA" && stats.denominazione_provincia === "Napoli")
    );

    dataToPlot.forEach((dayStats, idx) => {
      let diff = 0;
      if (Object.prototype.hasOwnProperty.call(dataToPlot, idx - 1)) {
        diff = dataToPlot[idx].totale_casi - dataToPlot[idx - 1].totale_casi;
      }
      news.push(diff);
      labels.push(new Date(dayStats.data.substr(0, 10)).toLocaleDateString());
      cases.push(dayStats.totale_casi);
    });

    if (this.movingAverage) {
      cases = round(movingAvg(cases));
      news = round(movingAvg(news));
    }

    this.chartdata = {
      labels,
      datasets: [
        {
          label: "Totale casi",
          backgroundColor: "navy",
          borderColor: "navy",
          fill: false,
          hidden: true,
          data: cases,
        },
        {
          label: "Nuovi positivi",
          backgroundColor: "blue",
          borderColor: "blue",
          fill: false,
          data: news,
        },
      ],
    };

    this.loaded = true;
  }

  private rePlot() {
    this.plot(this.province);
  }
}
